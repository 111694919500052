import get from 'lodash/get'
import {
  createClient,
  LiveClient,
  LiveTranscriptionEvents,
  LiveTranscriptionEvent
} from '@deepgram/sdk'
import jsLogger from 'js-logger'

const MILLISECOND = 1000
const PREFERRED_SAMPLE_RATE = 16000
const PACKET_DURATION = 500
const BUFFER_SIZE = 2048
const STEREO_FLOAT_TO_MONO_INT16 = 0x10000 / 4 - 1

class OpenAITranscriber {
  private _stream: MediaStream
  private _blobs: Blob[] = []
  private _dg?: LiveClient
  private _speech: string = ''
  private _onSpeech?: (s: string, addToBuffer?: boolean) => void
  private _onFinalDetected?: (transcript: string, latency?: number) => void
  private _interactionId: string
  private _source?: MediaStreamAudioSourceNode
  private _recorderNode?: AudioWorkletNode
  private _audioContext?: AudioContext
  private _key?: string
  private _dgConnected: boolean = false
  private _keepaliveTimeer: number = 0
  private _isSpeaking: boolean = false
  private _onStartTalking: () => void
  private _timeTranscript: Record<string, string> = {}
  private _lang = 'en'
  private _sampleRate = 16000
  private _stopped = false

  private ws: WebSocket | undefined

  private lastTranscript = {
    text: '',
    timestamp: 0
  }

  private _baseDGParams = {
    model: 'nova-3',
    smart_format: true,
    endpointing: 100,
    // no_delay: true,
    interim_results: true,
    // utterance_end_ms: 100,
    diarize: true,
    punctuate: true,
    channels: 1,
    encoding: 'linear16',
    // language: 'en-US',
    // language: 'multi',

    // utterances: true,
    numerals: false,
    vad_events: true
  }

  constructor (
    stream: MediaStream,
    onSpeach: (s: string) => void,
    onFinalDetected: (transcript: string) => void,
    interactionId: string,
    apiKey: string,
    onStartTalking: () => void,
    lang: string
  ) {
    this._stream = stream
    this._onSpeech = onSpeach
    this._onFinalDetected = onFinalDetected
    this._interactionId = interactionId
    this._key = apiKey
    this._onStartTalking = onStartTalking
    this._lang = lang
    this.start()

    // this._transcriberConnect(dgKey)
  }

  get speach () {
    return this._speech
  }

  private _resetSpeech = () => {
    jsLogger.log('reset speech')
    this._speech = ''
  }

  private _sendKeepalive = () => {
    if (this._dg && this._dg.isConnected()) {
      jsLogger.log('DG: send keepalive')
      this._dg.keepAlive()
    }
  }

  private compareStrings = (s1: string, s2: string) => {
    const normalizeString = (str: string) =>
      str.toLowerCase().replace(/[^a-z0-9]/g, '')
    return normalizeString(s1) === normalizeString(s2)
  }

  private _transcriberConnect = async () => {
    if (this._stopped) {
      jsLogger.debug('OPENAI: transcriber exit because already stopped')
      return
    }

    this.ws = new WebSocket(
      'wss://api.openai.com/v1/realtime?intent=transcription',
      [
        'realtime',
        // Auth
        'openai-insecure-api-key.' + this._key,
        // Beta protocol, required
        'openai-beta.realtime-v1'
      ]
    )

    this.ws.addEventListener('open', () => {
      jsLogger.log('OPENAI: Connected to server.')
      const conf = {
        type: 'transcription_session.update',
        session: {
          input_audio_format: 'pcm16',
          input_audio_transcription: {
            model: 'gpt-4o-mini-transcribe',
            // model: 'gpt-4o-transcribe',
            // prompt:
            //   'The user will say a four digits code that we sent to thier email.',
            language: this._lang
          },
          turn_detection: {
            type: 'server_vad',
            threshold: 0.5,
            prefix_padding_ms: 500,
            silence_duration_ms: 1000
          },
          input_audio_noise_reduction: {
            type: 'far_field'
          },
          include: ['item.input_audio_transcription.logprobs']
        }
      }
      jsLogger.log('transcriber conf', conf)
      this.ws && this.ws.send(JSON.stringify(conf))
    })

    this.ws.addEventListener('message', message => {
      const data = JSON.parse(message.data)
      jsLogger.log('OPENAI: message', data)
      this._onSocketMessage(data)
    })

    this.ws.addEventListener('error', function incoming (event) {
      jsLogger.log(get(event, 'message'))
    })

    // const _con = createClient(this._key)
    // jsLogger.log('DG: client created')
    // const dgParams = {
    //   ...this._baseDGParams,
    //   sample_rate: this._sampleRate,
    //   model: this._lang === 'en' ? 'nova-3' : 'nova-2',
    //   language: this._lang
    // }
    // try {
    //   this._dg = _con.listen.live(dgParams)
    //   jsLogger.debug('DG: listen.live', { dgParams })

    //   this._dg.on(LiveTranscriptionEvents.Error, e => {
    //     const strError = JSON.stringify(e, [
    //       'message',
    //       'arguments',
    //       'type',
    //       'name'
    //     ])
    //     jsLogger.error('DG ERROR:', { error: strError, eRaw: e })
    //     clearInterval(this._keepaliveTimeer)
    //     setTimeout(() => this._transcriberConnect(), 1000)
    //   })

    //   this._dg.on(LiveTranscriptionEvents.Open, async () => {
    //     jsLogger.debug('DG connected')
    //     this._dgConnected = true
    //     jsLogger.log('client: connected to websocket')

    //     this._keepaliveTimeer = window.setInterval(this._sendKeepalive, 5000)

    //     if (!this._dg) {
    //       jsLogger.error('Raudio: cannot add listeners, DG is not initialized')
    //       return
    //     }

    //     this._dg.on(
    //       LiveTranscriptionEvents.Transcript,
    //       (data: LiveTranscriptionEvent) => {
    //         // jsLogger.log('DG RESULTS:', data)
    //         const t = get(data.channel.alternatives, [0, 'transcript'])
    //         jsLogger.log('DG: on Transcript', { transcript: t, data })
    //         // const transcript = data.channel.alternatives[0].transcript
    //         this._onSocketMessage(data)
    //       }
    //     )

    //     this._dg.on(LiveTranscriptionEvents.UtteranceEnd, () => {
    //       this._onUtteranceEnd()
    //     })

    //     this._dg.on(LiveTranscriptionEvents.SpeechStarted, data => {
    //       jsLogger.log('DG: Speech started event', data)
    //       this._isSpeaking = true
    //     })

    //     this._dg.on('warning', e => jsLogger.warn('DG WARNING:', e))

    //     this._dg.on('Metadata', e => jsLogger.log('DG METADATA:', e))

    //     this._dg.on('close', e => {
    //       jsLogger.debug('DG CLOSE:', e)
    //       // this._dgConnected = false
    //       clearInterval(this._keepaliveTimeer)
    //       setTimeout(this._transcriberConnect, 500)
    //     })
    //   })
    // } catch (e) {
    //   const strError = JSON.stringify(e, [
    //     'message',
    //     'arguments',
    //     'type',
    //     'name'
    //   ])
    //   jsLogger.warn('DG connection error', { strError })
    // }
  }

  private _onUtteranceEnd = () => {
    jsLogger.log('DG: UTTERANCE END received')
    if (this._isSpeaking) {
      if (this._speech !== '') {
        if (this._onFinalDetected) {
          this._onFinalDetected(this._speech)
        }
        this._isSpeaking = false
        this._speech = ''

        this._onSpeech && this._onSpeech('')
      }
    }
  }

  private _onSocketMessage = (data: { type: string } & object) => {
    switch (data.type) {
      case 'input_audio_buffer.speech_started': {
        this._onStartTalking && this._onStartTalking()
        break
      }
      case 'conversation.item.input_audio_transcription.delta': {
        const delta = get(data, 'delta')
        if (delta !== '') {
          this._speech = (this._speech + ' ' + delta).trim()
          this._onSpeech && this._onSpeech(this._speech, false)
        }
        break
      }
      case 'conversation.item.input_audio_transcription.completed': {
        const t = get(data, 'transcript', '')
        this._onFinalDetected && this._onFinalDetected(t, 0)
        this._speech = ''
        this._isSpeaking = false
        this._onSpeech && this._onSpeech('', false)
      }
    }

    // // jsLogger.log('socket message', message)
    // // jsLogger.log('socket message:', received)
    // const t = get(received, ['channel', 'alternatives', 0, 'transcript'])
    // const isFinal = received.is_final
    // const speachFinal = received.speech_final
    // const timeTrKey = 'time_' + received.start

    // if (t !== '') {
    //   jsLogger.log('DG: transcript received ', {
    //     // message: t,
    //     t,
    //     isFinal,
    //     speachFinal
    //   })
    //   this._onStartTalking && this._onStartTalking()

    //   this._timeTranscript[timeTrKey] = t
    // }

    // if (isFinal && t !== '') {
    //   this._speech = (this._speech + ' ' + t).trim()
    //   this._onSpeech && this._onSpeech(this._speech)
    //   if (!speachFinal) {
    //     this.lastTranscript = {
    //       text: this._speech,
    //       timestamp: Date.now()
    //     }
    //   }
    // } else if (t !== '') {
    //   this._onSpeech && this._onSpeech((this._speech + ' ' + t).trim())
    //   if (!speachFinal) {
    //     this.lastTranscript = {
    //       text: (this._speech + ' ' + t).trim(),
    //       timestamp: Date.now()
    //     }
    //   }
    // }

    // if (isFinal && speachFinal) {
    //   jsLogger.log('DG: speech final detected', {
    //     speech: this._speech,
    //     timeTranscript: this._timeTranscript[timeTrKey],
    //     lastTranscript: this.lastTranscript,
    //     currentTimestamp: Date.now()
    //   })
    //   const phrase =
    //     this._speech !== '' ? this._speech : this._timeTranscript[timeTrKey]
    //   if (phrase && phrase.length > 0) {
    //     let latency = 0
    //     if (this.compareStrings(this.lastTranscript.text, phrase)) {
    //       latency =
    //         this.lastTranscript.timestamp > 0
    //           ? Date.now() - this.lastTranscript.timestamp
    //           : 0
    //     }
    //     this._onFinalDetected && this._onFinalDetected(phrase, latency)
    //     this._speech = ''
    //     this._isSpeaking = false
    //     this._onSpeech && this._onSpeech('')
    //   }
    // }
  }

  stop = () => {
    jsLogger.log('DG: STOP')
    if (this._dg && this._dgConnected) {
      this._dg.requestClose()
      clearInterval(this._keepaliveTimeer)
    }
    this._stopped = true
    this._source && this._source.disconnect()
    this._recorderNode && this._recorderNode.disconnect()
    this._audioContext &&
      this._audioContext.state !== 'closed' &&
      this._audioContext.close()
  }

  setLang = (l: string) => {
    jsLogger.log('DG: set lang', {
      toLang: l,
      currentLang: this._lang,
      isConnected: this._dg && this._dg.isConnected()
    })
    if (this._dg && this._dg.isConnected() && l !== this._lang) {
      jsLogger.log('DG, lang changed', { prevLang: this._lang, newLang: l })
      this._lang = l
      // const dgParams = {
      //   ...this._baseDGParams,
      //   sampleRate: this._sampleRate,
      //   language: this._lang
      // }
      // jsLogger.debug('DG: reconnecting with params:', { dgParams })
      this._dg.requestClose()
      // clearInterval(this._keepaliveTimeer)
      // this._transcriberConnect()
      // this._dg.reconnect(dgParams)
    }
  }

  start = async () => {
    jsLogger.debug('OpenAI: start')
    this._speech = ''
    this._audioContext = new AudioContext({ sampleRate: PREFERRED_SAMPLE_RATE })
    try {
      this._source = this._audioContext.createMediaStreamSource(this._stream)
    } catch (e) {
      this._audioContext = new AudioContext() // some browsers can’t return arbitrary audio sample rate, such as firefox
      this._source = this._audioContext.createMediaStreamSource(this._stream)
    }
    const packetSamples =
      (this._audioContext.sampleRate * PACKET_DURATION) / MILLISECOND
    let port: any
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    if (isSafari) {
      const recorder = this._audioContext.createScriptProcessor(BUFFER_SIZE)
      this._source.connect(recorder)
      port = {}
      let queueLength = 0
      const start = Date.now()
      let num = 0
      const queue = new Int16Array(packetSamples)
      recorder.onaudioprocess = function (e) {
        if (port.onmessage) {
          const left = e.inputBuffer.getChannelData(0)
          const right = e.inputBuffer.getChannelData(1) || left
          if (left && right) {
            for (let i = 0; i < left.length; i++) {
              queue[queueLength++] =
                (left[i] + right[i]) * STEREO_FLOAT_TO_MONO_INT16
              if (queueLength === queue.length) {
                queueLength = 0
                port.onmessage({
                  data: {
                    data: queue,
                    log: [Date.now(), Date.now() - start, ++num]
                  }
                })
              }
            }
          }
        }
      }
      recorder.connect(this._audioContext.destination)
    } else {
      const blob = new Blob(
        [
          `
registerProcessor("pcm-processor", class extends AudioWorkletProcessor {
  constructor(options) {
    super();
    this.start=Date.now();
	this.num=0;
	this.queue=new Int16Array(${packetSamples});
	this.queueLength=0;
  }
  process(input) {
    const left = input[0][0];
    const right = input[0][1]||left;
	if(left && right){
		for(let i=0;i<left.length;i++){
			this.queue[this.queueLength++]=(left[i]+right[i])*${STEREO_FLOAT_TO_MONO_INT16};
			if(this.queueLength===this.queue.length){
					this.queueLength=0;
					this.port.postMessage({data:this.queue,log:[Date.now(),Date.now()-this.start,++this.num]});
			}
		}
	}
    return true;
  }
});`
        ],
        { type: 'application/javascript; charset=utf-8' }
      )
      const url = URL.createObjectURL(blob)
      await this._audioContext.audioWorklet.addModule(url)
      this._recorderNode = new AudioWorkletNode(
        this._audioContext,
        'pcm-processor'
      )
      this._recorderNode.connect(this._audioContext.destination)
      port = this._recorderNode.port
      this._source.connect(this._recorderNode)
    }

    port.onmessage = ({ data }: { data: any }) => {
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        // const b = btoa(String.fromCharCode(...new Uint8Array(data.data.buffer)))
        const blob = new Blob([data.data.buffer])
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const audioData = reader.result?.toString().split(',')[1] || ''
          if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            this.ws.send(
              JSON.stringify({
                type: 'input_audio_buffer.append',
                audio: audioData
              })
            )
          }
        }
        // this.ws.send(
        //   JSON.stringify({
        //     type: 'input_audio_buffer.append',
        //     audio: b
        //   })
        // )
      } else {
        jsLogger.warn('OPENAI: ignore the blob, connected', {
          connected: this.ws ? this.ws.readyState : 'WS is not initialized'
        })
      }

      // const blob = new Blob([data.data.buffer])
      // const reader = new FileReader()
      // reader.readAsDataURL(blob)
      // reader.onloadend = () => {
      //   const audioData = reader.result?.toString().split(',')[1] || ''

      // }
      // if (this._dg && this._dgConnected) {
      //   this._dg.send(blob)
      // } else {
      //   jsLogger.warn('DG: ignore the blob, connected', {
      //     connected: this._dgConnected
      //   })
      // }
    }

    this._sampleRate = this._audioContext.sampleRate
    this._transcriberConnect()
  }
}

export default OpenAITranscriber
