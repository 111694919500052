import * as API from '@facesignai/api'

type Phrase = API.Phrase

export type Error = {
  error: string
}

export type SessionStartRequest = {
  version: string
  lang: string
}

export enum INTERRUPT {
  'SMART' = 'smart',
  'REGULAR' = 'regular',
  'DISABLED' = 'disabled'
}

export type AvatarProvider = 'heygen' | 'azure' | 'custom'

export type SessionAvatarSecret = {
  token: string
  region?: string
}

export type SessionAvatar = {
  avatarId?: string
  provider: AvatarProvider
  quality?: string
  imageUrl?: string
  // voiceId?: string
  secret: SessionAvatarSecret
}

export type SessionStartResponse = {
  sessionId: string
  steps: Phrase[]
  avatar: SessionAvatar
  transcriber: string
  transcriberKey: string
  availableLanguages: API.Lang[]
  interruptionMode: INTERRUPT
}

export type SubmitReplyRequest = {
  reply: string
}

export type SubmitReplyResponse = {
  phrase: Phrase
  isOver: boolean
  steps: Phrase[]
}

export type ApplyResponseRequest = {
  q: Phrase
  latencies?: { dgLatency: number; grLatency: number }
}

export type AvatarLatencyRequest = {
  phraseId: string
  latency: number
}

export type ApplyResponseResponse = {
  steps: Phrase[]
}

export type ControlT = {
  camera: boolean
  mic: boolean
}

export enum PermissionErrorType {
  explanation = 'explanation',
  systemDenied = 'systemDenied',
  userDenied = 'userDenied',
  trackError = 'trackError'
}
