import {
  ReactNode,
  useEffect,
  useState
} from 'react'
import jsLogger from 'js-logger'

const TEST_FILE_URL = '/testfile.bin'
const TEST_FILE_SIZE = 500000
const THRESHOLD = 5

const NetworkSpeedCheck = ({ component }: { component: ReactNode }) => {
  const [isSlow, setIsSlow] = useState<boolean>(false)
  useEffect(() => {
    const measureDownloadSpeed = async () => {
      try {
        await fetch(TEST_FILE_URL + '&warmup=1', { headers: { Origin: window.location.origin } })

        const results = await Promise.all([1, 2, 3].map(async () => {
          const start = performance.now()
          const response = await fetch(`${TEST_FILE_URL}&t=${Date.now()}`, {
            headers: { Origin: window.location.origin },
            cache: 'no-store'
          })
          await response.blob()
          const duration = (performance.now() - start) / 1000
          return (TEST_FILE_SIZE * 8) / (duration * 1e6)
        }))

        const res = results.sort()[1]
        jsLogger.log('network test res:', res)
        if (res < THRESHOLD) {
          jsLogger.warn(`network test < ${THRESHOLD}`)
          setIsSlow(true)
        }
      } catch (err) {
        jsLogger.error('network test failed', err)
      }
    }
    measureDownloadSpeed()
  }, [])
  if (isSlow) {
    return component
  }
  return null
}

export default NetworkSpeedCheck
