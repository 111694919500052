import React from 'react'
import { ControlT } from 'types/internal'

interface RecorderVideoProps {
  controls: ControlT
  cameraVideoRef: React.RefObject<HTMLVideoElement>
}

const RecorderVideo: React.FC<RecorderVideoProps> = ({
  controls,
  cameraVideoRef
}) => {
  return (
    <video
      className={`w-full h-full object-cover rounded-md scale-x-[-1] transform-gpu ${
        !controls?.camera || !controls.mic ? 'opacity-80' : 'opacity-100'
      }`}
      ref={cameraVideoRef}
      autoPlay
      muted
      playsInline
    />
  )
}

export default RecorderVideo
